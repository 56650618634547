<template>
  <div class="fill-height">
    <div class="d-block d-sm-none">
      <v-btn :ripple="false" class="pl-0" color="white" elevation="0" large @click="$emit('openMenu', true)">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </div>
    <div class="componentHeight">
      <v-tabs v-model="tab" background-color="primary" grow hide-slider>
        <v-tab class="white--text">
          CEMS
          <a class="a_forRightClick" href="?tab=1" @click.prevent></a>
        </v-tab>
        <v-tab class="white--text">
          WPMS
          <a class="a_forRightClick" href="?tab=0" @click.prevent></a>
        </v-tab>
      </v-tabs>
      <div style="height: calc(100% - 48px);">
        <v-tabs-items v-model="tab" class="contentItem" style="height: 100%;" touchless>
          <v-tab-item style="height: 100%;">
            <v-container>
              <div id="cems-general-header" class="px-3 d-flex flex-wrap">
                <h2>
                  ข้อมูลทั่วไป
                </h2>
                <v-spacer/>
                <div>
                  <v-btn class="px-2 mr-2" color="#f10000" elevation="0" style="color: white;" @click="exportPdf">
                    <v-icon class="pr-2">
                      mdi-file-pdf
                    </v-icon>
                    Download
                  </v-btn>
                  <v-btn class="px-2" color="#1D6F42" elevation="0" style="color: white;" @click="exportExcel">
                    <v-icon class="pr-2">
                      mdi-file-excel
                    </v-icon>
                    Download
                  </v-btn>
                </div>
              </div>
            </v-container>
            <div :style="`height: calc(100% - ${cemsHeaderHeight}px);`">
              <iframe title="ข้อมูลทั่วไป (CEMS)" src="https://poms.diw.go.th/jasperserver/rest_v2/reports/Reports/rpt_cems.html"></iframe>
            </div>
          </v-tab-item>
          <v-tab-item style="height: 100%;">
            <v-container>
              <div id="opms-general-header" class="px-3 d-flex flex-wrap">
                <h2>
                  ข้อมูลทั่วไป
                </h2>
                <v-spacer/>
                <div>
                  <v-btn class="px-2 mr-2" color="#f10000" elevation="0" style="color: white;" @click="exportPdf">
                    <v-icon class="pr-2">
                      mdi-file-pdf
                    </v-icon>
                    Download
                  </v-btn>
                  <v-btn class="px-2" color="#1D6F42" elevation="0" style="color: white;" @click="exportExcel">
                    <v-icon class="pr-2">
                      mdi-file-excel
                    </v-icon>
                    Download
                  </v-btn>
                </div>
              </div>
            </v-container>
            <div :style="`height: calc(100% - ${opmsHeaderHeight}px);`">
              <iframe title="ข้อมูลทั่วไป (WPMS)" src="https://poms.diw.go.th/jasperserver/rest_v2/reports/Reports/rpt_wpms.html"></iframe>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '../assets/js/utils'

export default {
  name: 'GeneralInfo',
  beforeMount () {
    const tab = this.$route.query.tab
    if (tab) {
      this.tab = parseInt(tab)
    }
  },
  mounted () {
    this.reCalculateHeight()
    window.addEventListener('resize', this.reCalculateHeight)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.reCalculateHeight)
  },
  data () {
    return {
      tab: 0,
      cemsHeaderHeight: 60,
      opmsHeaderHeight: 60
    }
  },
  methods: {
    reCalculateHeight () {
      const cemsHeader = document.getElementById('cems-general-header')
      const opmsHeader = document.getElementById('opms-general-header')
      if (cemsHeader) this.cemsHeaderHeight = cemsHeader.offsetHeight
      if (opmsHeader) this.opmsHeaderHeight = opmsHeader.offsetHeight
    },
    exportPdf () {
      let url = ''
      if (this.tab === 0) {
        url = 'https://poms.diw.go.th/jasperserver/rest_v2/reports/Reports/rpt_cems.pdf'
      } else if (this.tab === 1) {
        url = 'https://poms.diw.go.th/jasperserver/rest_v2/reports/Reports/rpt_wpms.pdf'
      }
      this.downloadUrl(url)
    },
    exportExcel () {
      let url = ''
      if (this.tab === 0) {
        url = 'https://poms.diw.go.th/jasperserver/rest_v2/reports/Reports/rpt_cems.xlsx'
      } else if (this.tab === 1) {
        url = 'https://poms.diw.go.th/jasperserver/rest_v2/reports/Reports/rpt_wpms.xlsx'
      }
      this.downloadUrl(url)
    },
    downloadUrl (url) {
      if (utils.getDeviceType() === 3) {
        window.open(url, 'Download')
      } else {
        window.longdoJsInterface.openUrl({ url: url, title: 'ส่งออกข้อมูล' })
          .then(
            (result) => {
              if (!result) console.warn('open url not success with unknown error')
            },
            (error) => {
              if (error.code !== 1) console.warn('open url not success with this code', error)
            }
          )
      }
    }
  },
  watch: {
    tab (to, from) {
      if (to !== from) {
        this.$router.replace({ name: this.$route.name, query: { tab: to } })
          .catch(() => {}) // remove redundant error
      }
      this.reCalculateHeight()
    }
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
  .componentHeight {
    height: calc(100% - 44px);
  }
}

@media (min-width: 600px) {
  .componentHeight {
    height: 100%;
  }
}

iframe {
  height: 100%;
  width: 100%;
  border: 2px solid #DCDCDC;
}

::v-deep .v-tab--active {
  background: rgb(158, 137, 77);
  background: -moz-linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  background: linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9e894d", endColorstr="#b7a676", GradientType=1);
}
</style>
